import { documentToHtmlString } from '@contentful/rich-text-html-renderer'
import { mapperModuleCodeSnippet, mapperModuleMedia, mapperModuleVideo } from '../data-layer/modules/mappers'

// TODO!!! CHANGE
// import khMappers from '../../knowledge-hub/data-layer/mappers'
import { mapperBlockCallToActionWithMedia, mapperBlockQuote } from '../data-layer/blocks/mappers'
import { mapperKnowledgeHubArticleSummary } from '../data-layer/knowledge-hub/summary/mapper-knowledge-hub-article-summary'
import type { MapperImageOptions, MapperOptions } from '#layers/core/types'

/**
 * List of CMS Node Types names that identify linked entries
 */
const EMBEDDED_NODE_TYPES = ['embedded-entry-block', 'entry-hyperlink']

/**
 * Wrap content with `<p>` tags when double line breaks are present
 * and replace simple line breaks with `<br>` tag
 */
const newLineToHtml = (value: string): string =>
  (value || '')
    .split('\n\n')
    .reduce((acc, content) => `${acc}<p>${content}</p>`, '')
    .replace(/(?:\r\n|\r|\n)/g, '<br>')

/**
 * Create a HTML table header cell element `<th>`
 */
const createTableThElement = (value: string): HTMLTableCellElement => {
  const headerCell = document.createElement('th')
  headerCell.innerHTML = newLineToHtml(value)
  return headerCell
}

/**
 * Content pattern to recognise where we will apply special content render
 */
const SPECIAL_PATTERN: RegExp = /\{(.)\}/

/**
 * Replace pattern like `{*}` with specific HTML tag and classes to apply particular styles
 */
const replaceSpecialPattern = (value: string): string =>
  (value || '').replace(SPECIAL_PATTERN, '<span class="table-icon $1">$1</span>')

/**
 * Check if pattern like `{*}` match the given value
 */
const matchSpecialPattern = (value: string): boolean => SPECIAL_PATTERN.test(value)

const populateLinkedEntries = (linkedEntriesIds: Set<string>, content = []) => {
  if (!content) {
    return
  }

  content.forEach((item) => {
    if (EMBEDDED_NODE_TYPES.includes(item.nodeType)) {
      linkedEntriesIds.add(item.data.target.sys.id)
    }
    if (item?.content?.length) {
      populateLinkedEntries(linkedEntriesIds, item.content)
    }
  })
}

/**
 * Create a HTML table element from CMS Module Table content type
 */
type CMSModuleTable = {
  table: any
  useColumnHeader: boolean
  useRowHeader: boolean
  fixedLayout: boolean
}

export const useRichTextUtils = () => {
  /**
   * Recursively iterate over the content arrays to get all embedded entries ids
   * The entries ids will be added to the `linkedEntriesIds` set
   */

  /**
   * Get Rich Text embedded entries ids
   */
  const getLinkedEntriesIds = (content = []) => {
    const linkedEntriesIds = new Set<string>()

    populateLinkedEntries(linkedEntriesIds, content)

    return linkedEntriesIds
  }

  const createTableModuleElement = (data: CMSModuleTable): HTMLTableElement => {
    const { useColumnHeader, useRowHeader, fixedLayout, table } = data
    const tableData = table?.tableData

    if (!tableData) {
      return document.createElement('table')
    }

    const tableElem = document.createElement('table')

    if (fixedLayout) {
      tableElem.classList.add('table-layout--fixed')
    }

    tableData.forEach((rowData: any, rowIndex: number) => {
      const row = tableElem.insertRow()
      const specialPatternColumnIndex = new Set()
      rowData.forEach((columnValue: any, columnIndex: number) => {
        const columnData = replaceSpecialPattern(columnValue)
        let cell
        if ((useColumnHeader && rowIndex === 0) || (useRowHeader && columnIndex === 0)) {
          cell = row.appendChild(createTableThElement(columnData))
        } else {
          cell = row.insertCell()
          cell.innerHTML = newLineToHtml(columnData)
        }

        if (matchSpecialPattern(columnValue)) {
          cell.classList.add('table-column--fixed-width')
          // save columns indexes that match the pattern
          specialPatternColumnIndex.add(columnIndex)
        }
      })

      if (useColumnHeader && specialPatternColumnIndex.size) {
        // apply special css class to headers of columns that matched the special pattern
        const firstRow = tableElem.getElementsByTagName('tr')[0]
        const columnHeaders = firstRow.getElementsByTagName('th')
        if (columnHeaders.length) {
          specialPatternColumnIndex.forEach((columnIndex: number) => {
            columnHeaders[columnIndex].classList.add('table-column--fixed-width')
          })
        }
      }
    })

    return tableElem
  }

  /**
   * Maps a node of type TABLE to a DsTable data object
   */
  const mapTableNodeToDsTableData = (node: any, firstRowHeader: boolean) => {
    if (!Array.isArray(node?.content) || node.content.length < 1) {
      return {}
    }
    const tableRows = [...node.content]

    // If first row header, we remove it from the rows eg: "Name, Price, ..."
    // If not, we still pass the first row to deduce the number of columns, but without removing it from results
    const firstRow = firstRowHeader ? tableRows.shift() : tableRows[0]

    const replaceTextReferenceIcons = (htmlString: string) => {
      if (!htmlString) {
        return ''
      }

      return htmlString
        .replace('{P}', '<span class="table-icon P">P</span>')
        .replace('{R}', '<span class="table-icon R">R</span>')
        .replace('{V}', '<span class="table-icon V"></span>')
        .replace('{X}', '<span class="table-icon X"></span>')
        .replaceAll('{G}', '<span class="table-icon G">.</span>')
        .replaceAll('{B}', '<span class="table-icon B">.</span>')
        .replaceAll('{RE}', '<span class="table-icon RE">.</span>')
        .replaceAll('{W}', '<span class="table-icon W">.</span>')
        .replaceAll('{GR}', '<span class="table-icon GR">.</span>')
        .replaceAll('{HG}', '<span class="table-icon HG">.</span>')
    }

    const columns = firstRow.content.map((cell, index) => ({
      key: `col${index + 1}`,
      text: firstRowHeader ? cell.content[0].content[0]?.value : ''
    }))

    const rows = tableRows.map((row, index) => {
      const rowContent = row.content.map((cell, colIndex) => [
        `col${colIndex + 1}`,
        replaceTextReferenceIcons(documentToHtmlString(cell))
      ])

      return {
        key: `rowGroup${index + 1}`,
        columns: Object.fromEntries(rowContent)
      }
    })

    const type = columns.length > 4 ? 'secondary' : 'primary'

    return {
      columns,
      rows,
      type
    }
  }

  const mapImageProps = (image: any, options: MapperImageOptions) => {
    const mappedImage = mapperModuleMedia(image, options)
    return {
      image: mappedImage,
      borderRadius: true
    }
  }

  const mapVideoProps = (video: any, options: MapperOptions) => {
    const mappedVideo = mapperModuleVideo(video, options.imageOptions)
    return {
      ...mappedVideo,
      borderRadius: true,
      ariaLabelPlay: options.labels?.play || 'Play',
      ariaLabelClose: options.labels?.close || 'Close'
    }
  }

  const mapCodeSnippetProps = (data: any, languageString: string) => {
    const mappedCodeSnippetProps = mapperModuleCodeSnippet(data)

    return {
      ...mappedCodeSnippetProps,
      // Code Snippet is only used in rich text with a fixed label
      label: `${languageString}: `
    }
  }

  const mapQuoteProps = (data: any, options: MapperOptions) => {
    const mappedQuoteProps = mapperBlockQuote(data, options)

    if (!mappedQuoteProps) {
      return {}
    }

    // Map just specific props from Block Quote
    const props = {
      logo: mappedQuoteProps.logo ? mappedQuoteProps.logo.original : null,
      text: mappedQuoteProps.quote,
      author: mappedQuoteProps.author,
      extraInfo: mappedQuoteProps.role,
      link: mappedQuoteProps.link
    }
    return props
  }

  const mapCardBlogProps = (data: any, options: MapperOptions) => {
    const mappedMediaCardProps = mapperKnowledgeHubArticleSummary(data, options)

    return {
      ...mappedMediaCardProps,
      contained: true,
      theme: 'grey',
      horizontalMinMedium: true
    }
  }

  const mapMediaTextCardProps = (data: any, options: MapperOptions) => {
    const mappedMediaTextCardProps = mapperBlockCallToActionWithMedia(data, options)

    return {
      ...mappedMediaTextCardProps,
      contained: true,
      fontSizeSmall: true,
      left: true
    }
  }

  const mapHeadingProps = (data: any, { hTag }: { hTag: string }) => {
    const headingClass = {
      h1: 'ds-heading-xlarge',
      h2: 'ds-heading-large',
      h3: 'ds-heading-medium',
      h4: 'ds-heading',
      h5: 'ds-heading-small',
      h6: 'ds-heading-xsmall'
    }

    const text = data.content.reduce((acc, c) => `${acc} ${c.value}`, '')

    return {
      attributes: {
        class: headingClass[hTag]
      },
      text
    }
  }

  return {
    getLinkedEntriesIds,
    createTableModuleElement,
    mapTableNodeToDsTableData,
    mapImageProps,
    mapVideoProps,
    mapCodeSnippetProps,
    mapQuoteProps,
    mapCardBlogProps,
    mapMediaTextCardProps,
    mapHeadingProps
  }
}
